<template>
  <div >
    <v-container class="pa-0 px-1" style="position: relative" >
      <div v-if="service.length>0">
      <swiper class="itemswiper px-4" :options="swiperOption">

        <swiper-slide v-for="item in service" :key="item.id" style="position: relative;" class="overflow-hidden">
<!--          <router-link :to="{name:'singleservice',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">-->
          <div @click="itemSelect(item.id)">
<!--            <a :href="href" @click="navigate" role="link">-->
            <div v-if="item.image" style="cursor: pointer" >
              <v-img :src=" $store.state.thumbnail + item.image" width="100%" height="260px" style="object-fit: cover">
              </v-img>
            </div>
            <p v-else class="primary--text" style="user-select: none" >بدون تصویر</p>
              <div class="swiper-tedad">
                <p class="ma-0">{{parseInt(item.images) + 1}}</p>
              </div>
            <div class="swiper-desc">
            <p style="user-select: none" >{{item.title}}</p>
            </div>
<!--            </a>-->
          </div>
<!--          </router-link>-->




        </swiper-slide>
      </swiper>


      <div class="button-prev swiper-button-prev swiper-button-white arrowsize-r" slot="button-prev"></div>
      <div class="button-next swiper-button-next swiper-button-white arrowsize-l" slot="button-next"></div>
      </div>
      <p v-else-if="loaded" class="text-center" >خدماتی موجود نیست</p>
    </v-container>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {EffectFade} from 'swiper';
import {Navigation} from 'swiper';
SwiperCore.use([Navigation]);
SwiperCore.use([EffectFade]);

export default {
  name: 'App',
  props : {
    service : null,
    loaded : {
      default : false,
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1280: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          1125: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          960: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          450: {
            slidesPerView: 1,
            spaceBetween: 30
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 30
          },

          300: {
            slidesPerView: 1,
            spaceBetween: 30
          }
        },
        navigation: {
          nextEl: '.button-prev',
          prevEl: '.button-next'
        }
      },
    }
  },
  components : {
    Swiper,
    SwiperSlide,

  },
  methods : {
    itemSelect(id){
      this.$emit('serivceid',id);
    }
  }
};
</script>

<style lang="scss" scoped>

@import "../assets/css/itemswiper";


.arrowsize-r {

  left : -5px !important;
  color: #570654 !important;
  --swiper-navigation-size: 25px !important;
}

.arrowsize-l {

  right : -5px !important;
  color: #570654 !important;
  --swiper-navigation-size: 25px !important;
}

.swiper-button-prev{
  background-image: url(../assets/image/Company/arrow-left.png);
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: center;
}

.swiper-button-next{
  background-image: url(../assets/image/Company/arrow-right.png);
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
  font-family: 'swiper-icons' !important;
  font-weight: 1000;
  content: "next";
}

.swiper-button-prev::after {
  display: none;
  font-family: 'swiper-icons' !important;
  font-weight: 1000;
  content: "prev";
}

.swiper-tedad{
  height: 35px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 20%;
}

.swiper-desc{
  min-height: 70px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  bottom: -14px;
  right: 0;
  background-color: rgba(87, 6, 84,0.7);
  width: 100%;
}

</style>
