<template>
  <div class="company" style="padding-top: 8vw;" :class="{'bgimg' : !bannerimage}"
       :style="[bannerimage?{backgroundImage : 'url('+$store.state.upload + bannerimage +')'}:{background : '#fff'}]">

    <p v-if="bannerimage" class="companytitle ma-0" style="cursor: pointer" @click="showDialog(bannerimage)" >
      <span v-if="loaded" >شرکت {{ cat.title }}
        {{title
      }}</span>
    </p>
    <p v-else class="companytitle ma-0" ><span v-if="loaded">شرکت {{ cat.title }}
      {{title
      }}</span></p>

    <v-dialog
        v-model="showimage"
        max-width="800"
    >
      <v-img contain :src="$store.state.upload + dialogImage" width="100%" max-height="500px" style="background-color: grey">
        <template v-slot:default>
          <div>
            <svg @click="showimage=false" style="width:24px;height:24px;cursor: pointer;margin-top: 5px;margin-right: 5px" viewBox="0 0 24 24">
              <path fill="white" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
            </svg>
          </div>
          <a :href="$store.state.upload + dialogImage" target="_blank">
            <div style="height: 100%;width: 100%;cursor: pointer">

            </div>
          </a>
        </template>

      </v-img>
    </v-dialog>


    <v-dialog
        v-model="showservice"
        width="100%"
        style="z-index: 99999"
    >
      <SingleService v-if="selectedserviceid" :selected="selectedserviceid"></SingleService>
<!--      <svg @click="showimage=false" style="width:24px;height:24px;cursor: pointer;margin-top: 5px;margin-right: 5px" viewBox="0 0 24 24">
        <path fill="white" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
      </svg> -->

    </v-dialog>

    <v-container class="px-0 d-flex flex-wrap" style="margin-top: 6.7vw">

      <div class="side-col">
        <p class="ma-0 white--text d-md-none d-block"
           style="margin-right: 23px !important;">خانه > <router-link :to="{name:'cats'}" custom v-slot="{navigate,href}"><a @click="navigate" :href="href" role="link" class="bcrumbs" style="color: white">دسته بندی شرکت ها</a></router-link> > <router-link :to="{name:'cats',query:{cat:cat.id}}" custom v-slot="{navigate,href}"><a @click="navigate" :href="href" role="link"  class="bcrumbs" style="color: white">{{cat.title}}</a></router-link></p>
        <div class="companylogo mt-3 mt-md-8 mb-8">
          <div class="companyimg">
            <img v-if="logoimage" :src="$store.state.thumbnail + logoimage" :alt="title" :title="title" @click="showDialog(logoimage)" style="max-width: 70%;max-height: 100%;cursor: pointer"/>
            <img alt="لوگوی ایران آی سیب" title="ایران آی سیب" v-else src="../assets/image/Header/logo.png" style="max-width: 70%;opacity: 0.2"/>
          </div>
        </div>

        <ContantBlock class="mb-8">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon2.png"/>
          <span slot="title">اطلاعات شرکت / مجموعه</span>
          <div slot="contant">
            <div v-if="title" class="d-flex justify-space-between flex-wrap">
              <p class="propertieskey">شرکت / مجموعه :</p>
              <p class="propertiesvalue">{{ title }}</p>
            </div>
            <v-divider v-if="title" class="my-4" style="border-color: #f9f9f9"></v-divider>

            <div v-if="cat.title" class="d-flex justify-space-between flex-wrap" >
              <p class="propertieskey">حوزه فعالیت :</p>
            <p class="propertiesvalue">{{ cat.title }}</p>
            </div>
            <v-divider v-if="cat.title" class="my-4" style="border-color: #f9f9f9"></v-divider>

            <div v-if="brand" class="d-flex justify-space-between flex-wrap">
              <p class="propertieskey">برند تجاری :</p>
              <p class="propertiesvalue">{{ brand }}</p>
            </div>
            <v-divider v-if="brand" class="my-4" style="border-color: #f9f9f9"></v-divider>

            <div v-if="fixedNumber" class="d-flex justify-space-between flex-wrap" >
              <p class="propertieskey">شماره تلفن ثابت :</p>
              <a style="text-decoration: none" :href="'tel:'+fixedNumber"
                 class="propertiesvalue textlinkhover">{{ fixedNumber }}</a>
            </div>
            <v-divider v-if="fixedNumber" class="my-4" style="border-color: #f9f9f9"></v-divider>


            <div v-for="(item,i) in otherFixedNumber" :key="i + 'fixed'">
            <div class="d-flex justify-space-between flex-wrap" >
              <p class="propertieskey">شماره تلفن ثابت {{i+2}} :</p>
              <a style="text-decoration: none" :href="'tel:'+item"
                 class="propertiesvalue textlinkhover">{{ item }}</a>
            </div>
            <v-divider class="my-4" style="border-color: #f9f9f9"></v-divider>
            </div>


            <div v-if="coTell" class="d-flex justify-space-between flex-wrap">
              <p class="propertieskey">شماره همراه :</p>
              <a style="text-decoration: none" :href="'tel:'+coTell" class="propertiesvalue textlinkhover">{{ coTell }}</a>
            </div>
            <v-divider v-if="coTell" class="my-4" style="border-color: #f9f9f9"></v-divider>

            <div v-for="(tell,i) in otherTell" :key="i+'tell'" >
            <div class="d-flex justify-space-between flex-wrap">
              <p class="propertieskey">شماره همراه {{i+2}} :</p>
              <a style="text-decoration: none" :href="'tel:'+tell" class="propertiesvalue textlinkhover">{{ tell }}</a>
            </div>
            <v-divider class="my-4" style="border-color: #f9f9f9"></v-divider>
            </div>


            <div v-if="faxNumber" class="d-flex justify-space-between flex-wrap">
              <p class="propertieskey">شماره فکس :</p>
              <a style="text-decoration: none" :href="'fax:'+faxNumber" class="propertiesvalue textlinkhover">{{faxNumber }}</a>
            </div>
            <v-divider v-if="faxNumber" class="my-4" style="border-color: #f9f9f9"></v-divider>
            <div v-for="(item,i) in otherFaxNumber" :key="i + 'fax'">
            <div class="d-flex justify-space-between flex-wrap">
              <p class="propertieskey">شماره فکس {{i+2}} :</p>
              <a style="text-decoration: none" :href="'fax:'+item" class="propertiesvalue textlinkhover">{{item }}</a>
            </div>
            <v-divider class="my-4" style="border-color: #f9f9f9"></v-divider>
            </div>
            <!--            <p class="propertiesvalue ma-0">02188918571</p><p class="propertieskey">شماره فکس : </p>
                        <v-divider class="my-4" style="border-color: #f9f9f9"></v-divider>-->
            <!--            <p class="propertiesvalue ma-0">{{ email }}</p>
                        <p class="propertieskey">ایمیل :</p>
                        <v-divider class="my-4" style="border-color: #f9f9f9"></v-divider>-->
            <div v-if="coEmail" class="d-flex justify-space-between flex-wrap">
              <p class="propertieskey">ایمیل شرکت :</p>
              <a style="text-decoration: none" :href="'mailto:'+coEmail" class="propertiesvalue textlinkhover">{{ coEmail }}</a>
            </div>
            <v-divider v-if="coEmail" class="my-4" style="border-color: #f9f9f9"></v-divider>

            <div v-if="siteUrl" class="d-flex justify-space-between flex-wrap">
              <p class="propertieskey">آدرس سایت :</p>
              <a style="text-decoration: none" :href="'https://'+siteUrl" target="_blank" class="propertiesvalue textlinkhover">{{
                  siteUrl
                }}</a>
            </div>
            <v-divider v-if="siteUrl" class="my-4" style="border-color: #f9f9f9"></v-divider>


            <div v-if="province.name || city.name" class="d-flex justify-space-between flex-wrap">
              <p class="propertieskey">استان / شهر : </p>
              <p class="propertiesvalue">{{ province.name }} ، {{ city.name }}</p>
            </div>
            <v-divider v-if="province.name || city.name" class="my-4" style="border-color: #f9f9f9"></v-divider>


            <div v-if="address">
              <p class="propertieskey mb-0">آدرس / نشانی :</p>
              <p class="propertiesvalue mr-2" style="font-size: 16px">{{ address }}</p>
<!--              <v-divider class="mt-4" style="border-color: #f9f9f9"></v-divider>-->
            </div>

              <div v-for="(item,i) in otherAddress" :key="i+'address'">
                <v-divider class="my-4" style="border-color: #f9f9f9"></v-divider>
              <p class="propertieskey mb-0">آدرس / نشانی {{i+2}} :</p>
              <p class="propertiesvalue mr-2" style="font-size: 16px">{{ item }}</p>
              </div>

            <div v-if="long" style="height: 250px" class="mt-2">
              <Map :marker="[long,lat]" :image="logoimage" ></Map>
            </div>

          </div>
        </ContantBlock>

        <ContantBlock class="mb-8">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon4.png"/>
          <span slot="title">دانلود رزومه</span>
          <div slot="contant" class="d-flex justify-center">
            <v-btn
                v-if="pdf"
                height="40"
                class="btn mb-5"
                style="font-size: 16px !important;background-color: #403f3f !important"
                :href="$store.state.upload + pdf"

            >
              دریافت رزومه شرکت
            </v-btn>
            <p v-else-if="loaded">رزومه ای موجود نیست</p>
          </div>
        </ContantBlock>

        <ContantBlock class="mb-8">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon6.png"/>
          <span slot="title">شبکه های اجتماعی</span>
          <div slot="contant" class="socialspace flex-wrap justify-space-between pb-4">
            <a v-if="linkedin" :href="'https://www.linkedin.com/'+linkedin" target="_blank">
              <img src="../assets/image/Public Icons/Social/linkedin.png" style="margin-top: 2px" height="40"
                   width="40"/>
            </a>
            <a v-if="instagram" :href="'https://www.instagram.com/'+instagram" target="_blank">
              <img src="../assets/image/Public Icons/Social/instagam.png" style="margin-top: 2px" height="40"
                   width="40"/>
            </a>
            <a v-if="facebook" :href="'https://www.facebook.com/'+facebook" target="_blank">
              <img src="../assets/image/Public Icons/Social/facebook.png" style="margin-top: 2px" height="40"
                   width="40"/>
            </a>
            <a v-if="aparat" :href="'https://www.aparat.com/'+aparat" target="_blank">
              <img src="../assets/image/Public Icons/Social/aprat.png" style="margin-top: 2px" height="40" width="40"/>
            </a>
            <a v-if="whatsApp" :href="'https://wa.me/'+whatsApp" target="_blank">
              <img src="../assets/image/Public Icons/Social/whatsapp.png" style="margin-top: 2px" height="40"
                   width="40"/>
            </a>
            <a v-if="telegram" :href="'https://t.me/'+telegram" target="_blank">
              <img src="../assets/image/Public Icons/Social/telegram.png" style="margin-top: 2px" height="40"
                   width="40"/>
            </a>

          </div>
        </ContantBlock>

        <ContantBlock class="mb-8">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon7.png"/>
          <span slot="title">آگهی های فعال</span>
          <div slot="contant">
            <div v-if="ads.length>0">
              <div v-for="ad in ads" :key="ad.id">
                <div class="d-flex justify-space-between">

                  <router-link :to="{name:'singlead',params:{ id : ad.id,title:ad.title}}" custom v-slot="{navigate,href}" >
                  <a class="propertieskey textlinkhover ma-0" @click="navigate" :href="href" role="link" style="font-size: 14px">{{ ad.title }}</a>
                  </router-link>
                <p class="propertiesvalue ma-0 mt-1" style="font-size: 12px;color: #b5b5b5">
                  {{ ad.createTime | momentHour }}</p>
                </div>
                <v-divider class="my-4" style="border-color: #f9f9f9"></v-divider>
              </div>
            </div>
            <p v-else-if="loaded" class="text-center">آگهی فعالی موجود نیست</p>
          </div>
        </ContantBlock>

      </div>
      <div class="contant-col pr-md-6">

        <p class="ma-0 white--text d-md-block d-none"
           style="margin-right: 23px !important;">خانه > <router-link :to="{name:'cats'}" custom v-slot="{navigate,href}"><a @click="navigate" :href="href" role="link" class="bcrumbs" style="color: white">دسته بندی شرکت ها</a></router-link> > <router-link :to="{name:'cats',query:{cat:cat.id}}" custom v-slot="{navigate,href}"><a @click="navigate" :href="href" role="link"  class="bcrumbs" style="color: white">{{cat.title}}</a></router-link> </p>

        <ContantBlock class="mt-2 mb-8">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon1.png"/>
          <span slot="title">معرفی مجموعه</span>
          <div slot="contant">
              <p class="abouttxt">
                <read-more more-str="مشاهده بیشتر" :text="desc" link="#" less-str="x بستن" :max-chars="300"></read-more>
              </p>
            <v-divider class="my-10" style="border-color: #e5e5e5"></v-divider>
            <div class="d-flex flex-wrap justify-center">
              <!--              <img src="../assets/image/Company/aparat.png" class="mb-6 mx-auto" width="75%"/>-->


              <div v-if="video || videoUrl">
                <p v-if="video" class="defaultcolor" style="font-size: 18px;">ویدیو</p>
              <video v-if="video" class="mb-5" ref="video" width="622" controls>
                <source :src="$store.state.upload + video" type="video/mp4">
                مرورگر شما ویدیو html را پشتیبانی نمی کند.
              </video>



              <div style="width: 100%" v-if="videoUrl">
                <br>
                <p class="defaultcolor" style="font-size: 18px;">ویدیو آپارات</p>
              <div v-html="videoUrl" style="width: 622px" >

              </div>
              </div>

              </div>
              <p v-else-if="loaded" class="text-center">ویدیویی موجود نیست</p>

              <!--              <video v-if="videoUrl && !video" class="mb-5" ref="video" width="622" controls>
                              <source :src="$store.state.upload + videoUrl" type="video/mp4">
                              مرورگر شما ویدیو html را پشتیبانی نمی کند.
                            </video>-->
              <!--              <div class="h_iframe-aparat_embed_frame" style="width: 622px"><span style="display: block;padding-top: 57%;width: 100%"></span><iframe :src="videoUrl" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>-->
            </div>
          </div>
        </ContantBlock>
        <ContantBlock :distance="18" class="mb-8 px-2 pb-12">

          <img slot="image" src="../assets/image/Public Icons/Side icons/icon3.png"/>
          <span slot="title">خدمات / محصولات</span>

          <div slot="contant">
            <ItemsSwiper v-if="this.service.length>0" :service="service" :loaded="loaded" @serivceid="serviceId($event)"></ItemsSwiper>
            <p v-else-if="loaded" class="text-center">خدمات / محصولات موجود نیست</p>
          </div>
        </ContantBlock>
<!--        <ContantBlock :distance="18" class="mb-8 px-2 pb-12">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon5.png"/>
          <span slot="title">نمایشگاه و رویداد‌ها</span>
          <div slot="contant">
            <ItemsSwiper2></ItemsSwiper2>
          </div>
        </ContantBlock>-->
        <ContantBlock class="mb-8">
          <img slot="image" src="../assets/image/Public Icons/Side icons/icon8.png"/>
          <span slot="title">ارتباط مستقیم با ما</span>
          <div slot="contant" class="contantcompany">

            <v-text-field
                background-color="white"
                label="نام شرکت / مجموعه شما"
                height="50"
                outlined
                v-model="companyNamefield"
                color="#570654"
                class="rounded-0"
            ></v-text-field>

            <!--            <v-text-field
                            background-color="white"
                            label="حوزه فعالیت"
                            height="58"
                            outlined
                            color="#570654"
                            class="rounded-0"
                        ></v-text-field>-->

            <v-text-field
                background-color="white"
                label="ایمیل شما"
                height="50"
                outlined
                v-model="emailfield"
                color="#570654"
                class="rounded-0"
            ></v-text-field>

            <v-textarea
                background-color="white"
                label="پیام شما"
                outlined
                v-model="textfield"
                color="#570654"
                class="rounded-0"
            ></v-textarea>

            <v-btn
                class="btn d-block mr-auto mt-8"
                @click="sendmessage"
                :loading="loading"
            >
              ارسال پیام
            </v-btn>

          </div>
        </ContantBlock>
      </div>

    </v-container>

  </div>
</template>

<script>
import ContantBlock from "@/components/ContantBlock";
import ItemsSwiper from "@/components/ItemsSwiper";
import Map from "@/components/Company/Map";

/*import ItemsSwiper2 from "@/components/ItemsSwiper2";*/
import axios from "axios";
import {momentfilter} from "@/plugins/moment";
import Vue from 'vue'
import ReadMore from 'vue-read-more';
Vue.use(ReadMore);
import SingleService from "./SingleService";

export default {
  name: 'App',
  mixins: [momentfilter],
  data() {
    return {
      id: this.$route.params.id,
      work: null,
      logoimage: '',
      bannerimage: '',
      pdf: '',
      video: '',
      videoUrl: '',
      desc: '',
      title: this.$route.params.title,
      catId: '',
      brand: '',
      fixedNumber: '',
      coEmail: '',
      coTell: '',
      faxNumber: '',
      email: '',
      whatsApp: '',
      siteUrl: '',
      provinceId: '',
      cityId: '',
      address: '',
      linkedin: '',
      instagram: '',
      facebook: '',
      aparat: '',
      youtube: '',
      telegram: '',
      cat: {},
      city: {},
      province: {},
      ads: [],
      service: [],
      otherTell : [],
      otherAddress : [],
      otherFaxNumber : [],
      otherFixedNumber : [],
      lat : null,
      long : null,
      loaded: false,
      textfield: '',
      emailfield: '',
      companyNamefield: '',
      loading: false,
      dialogImage : null,
      showimage:false,
      showservice : false,
      selectedserviceid : null,

    }
  },
  components: {
    ContantBlock,
    ItemsSwiper,
    Map,
    SingleService,
/*    ItemsSwiper2*/
  }
  ,
  metaInfo() {
    return {
      title: this.title
      ,link: [{rel: 'canonical', href: this.$store.state.url+'/company/'+this.id+'/'+this.title}],
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.limit(this.desc),
        },
        {
          property: 'og:description',
          content:this.limit(this.desc),
          vmid: 'og:description'
        },

        {
          property: 'og:image',
          vmid: 'og:image',
          content: this.$store.state.thumbnail + this.logoimage
        }, {
          property: 'og:title',
          vmid: 'og:title',
          content: 'ایران آی سیب' + '::' + this.title
        }
      ],
    }
  },
  watch : {
    showservice(){
      if(this.showservice===false){
        this.selectedserviceid = null;
      }
    }
  },


  methods: {
    limit(data) {
      if (data.length > 150) {
        return data.substr(0, 147)+'...';
      }else {
        return data;
      }
    },
    serviceId(id){
      this.selectedserviceid = id;
      this.showservice = true;
    },
    showDialog(image){
      this.dialogImage = image;
      this.showimage=true;
    },
    sendmessage() {
      this.loading = true;
      axios.post(this.$store.state.api + 'contactus', {
        type: 'work',
        targetId: this.id,
        text: this.textfield,
        email: this.emailfield,
        companyName: this.companyNamefield,
      }).then(response => {
        if (response.data.result) {
          this.textfield = '';
          this.emailfield = '';
          this.companyNamefield = '';
          this.loading = false;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'پیام شما ارسال شد';
        }
      })
    }
  },
  created() {
    axios.post(this.$store.state.api + 'singlework', {
      id: this.id,
    }).then(response => {
      this.work = response.data.data.work;
      this.logoimage = this.work.image;
      this.bannerimage = this.work.banner;
      this.pdf = this.work.cvImage;
      this.video = this.work.video;
      this.videoUrl = this.work.videoUrl;
      this.desc = this.work.desc;
      this.title = this.work.title;
      this.catId = this.work.catId;
      this.brand = this.work.brand;
      this.fixedNumber = this.work.fixedNumber;
      this.email = this.work.email;
      this.siteUrl = this.work.siteUrl;
      this.provinceId = this.work.provinceId;
      this.cityId = this.work.cityId;
      this.address = this.work.address;
      this.linkedin = this.work.linkedin;
      this.instagram = this.work.instagram;
      this.facebook = this.work.facebook;
      this.aparat = this.work.aparat;
      this.whatsApp = this.work.whatsApp;
      this.telegram = this.work.telegram;
      this.coTell = this.work.coTell;
      this.coEmail = this.work.coEmail;
      this.long = this.work.long;
      this.lat = this.work.lat;
      this.faxNumber = this.work.faxNumber;
      this.otherAddress = JSON.parse(this.work.otherAddress);
      this.otherFaxNumber = JSON.parse(this.work.otherFaxNumber);
      this.otherFixedNumber = JSON.parse(this.work.otherFixedNumber);
      this.otherTell = JSON.parse(this.work.otherTell);

      this.cat = response.data.data.cat;
      this.city = response.data.data.city;
      this.province = response.data.data.province;
      this.ads = response.data.data.ads;
      this.service = response.data.data.service;


      this.loaded = true;

    }).catch(error=>{
      this.$router.push('/404');
      console.log(error);
      this.$store.state.notify.is = true;
      this.$store.state.notify.text = 'این کسب و کار وجود ندارد';
    })
  }
};
</script>

<style lang="scss">

.company {
  background-repeat: no-repeat;
  background-size: 100% 45vw;
}

.bgimg {
  background: url("../assets/image/Company/nullhead.jpg") no-repeat !important;
  background-size: 100% 45vw !important;
}

@media only screen and (min-width: 600px) {
  .bgimg {
    background-size: 100% 28.391vw !important;
  }
  .company {
    background-size: 100% 28.391vw;
  }
}

.companytitle {
  font-size: 2.92vw;
  user-select: none;
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 6px 5px rgba(0, 0, 0, 0.8);
  letter-spacing: 0.6px;
}

@media only screen and (max-width: 768px) {
  .companytitle {
    font-size: 18px !important;
  }
}

.companylogo {
  background-color: #fcfcfc;
  padding: 23px;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08) !important;
  height: 320px;
  overflow: hidden;
}

.abouttxt {
  font-size: 18px;
  color: #570654;
  text-align: justify;
  text-align-last: right;
}

.abouttxt--height {
  max-height: 120px;
}

.companyimg {
  height: 100%;
  border: 3px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contantcompany .v-input .v-label {
  color: #a3a3a3 !important;
  font-size: 14px !important;
}

.contantcompany .v-text-field .v-label--active {
  color: #570654 !important;
}

.contantcompany .v-text-field fieldset {
  border-color: #e8e8e8;
}

.contantcompany .v-input__slot {
  margin-bottom: 4px !important;
}

.contantcompany .v-text-field__details {
  margin-bottom: 4px !important;
}

.contantcompany .v-input--is-focused fieldset {
  border-color: #570654 !important;
}

.contantcompany .v-input {
  font-size: 14px !important;
}

.socialspace {
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 35px);
  grid-gap: 12px;
}

.socialspace::after {
  content: "";
  flex: auto;
}

#readmore{
  text-decoration: none !important;
  font-size: 16px;
  user-select: none;
  cursor: pointer;
  color: #570654;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #ececec;
}

.bcrumbs{
  cursor: pointer;
}

.bcrumbs:hover{
  color: #ae8aff !important;
}
/*.wrapper{
  display:table;
  position:relative;
}
.playpause {
  background-image:url('../assets/image/Company/playicon.png');
  background-repeat:no-repeat;
  width:50%;
  height:50%;
  position:absolute;
  left:0%;
  right:0%;
  top:0%;
  bottom:0%;
  margin:auto;
  background-size:contain;
  background-position: center;
}*/

</style>

<!--<style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style>-->
