<template>
  <mapir :zoom="zoom" class="map" :apiKey="$store.state.mapApi" :center="marker"
  >
    <mapMarker
        :coordinates.sync="marker"
        color="blue"
    >
      <span v-if="image" slot="marker">
    <v-img class="marker" :src="$store.state.smallthumbnail+image"></v-img>
</span>
    </mapMarker>

  </mapir>
</template>

<script>
import {
  mapir,
  mapMarker,
} from "mapir-vue";

export default {
  name: 'App',
  props : {
    marker : null,
    image : null,
  },

  data() {
    return {
      zoom:14,
    }
  },
  components : {
    mapMarker,
    mapir,
  }
};
</script>


<style>
.map {
  border-radius: 2px;
  overflow: hidden;
  border: 5px #fcfcfc solid;
  box-shadow: none !important;
  width: 100%;
  height: 100%;
}

.marker{
  border: 2px solid #fff ;
  box-shadow: 0px 3px 3px #999;
  width: 40px;
  cursor: pointer;
  border-radius: 100%;
  background-color: white;
}

</style>

<style lang="scss">
.v-application .blue{
  background-color: transparent !important;
  border-color: transparent !important;
}
</style>
